import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Modal, Button, Input } from '@mantine/core';
import classNames from 'classnames';

interface IOption {
  value: string;
  label: string;
  children?: IOption[];
  disabled?: boolean;
}

interface ISelected {
  project: string | undefined;
  category?: string;
  task?: string;
}

interface IProps {
  opened: boolean;
  onClose: () => void;
  options: IOption[];
  defaultSelected?: ISelected;
  onSelect: (selected: ISelected) => void;
  recentProjects?: string[];
}

const Option = ({ key, disabled, onClick, label, isSelected, recentProject }: { key: string, onClick: () => void, label: string, isSelected: boolean, disabled?: boolean, recentProject?: boolean }) => {
  return (
    <div key={key} onClick={onClick}
      className={classNames("p-4 my-1 cursor-pointer bg-gray-100 border-gray-200 hover:bg-gray-300 hover:border-gray-300 mr-2 md:mr-0 min-w-[200px] border-2 relative", {
        'bg-gray-300 ': isSelected,
        'bg-gray-200': !disabled && !isSelected,
        'border-dotted pointer-events-none': disabled,
        'border-solid': !disabled,
        'border-gray-600 hover:border-gray-600': isSelected,
      })}>
      <div className={classNames('font-semibold line-clamp-3 text-ellipsis overflow-hidden', {
        'text-gray-400': disabled,
      })}>
        {label}
        {recentProject && <div className="absolute bottom-0 right-0 pr-1 text-[10px] text-gray-500">Recently Used</div>}
      </div>
    </div>
  );
};

const TaskSelectorModal = ({ opened, onClose, options, defaultSelected = { project: undefined, category: undefined, task: undefined }, onSelect, recentProjects = [] }: IProps) => {
  const isMobile = useMediaQuery('(max-width: 80em)');
  const [selected, setSelected] = React.useState<ISelected>(defaultSelected);
  const [isLoading, setIsLoading] = React.useState(false);
  const categories = options?.find((option: any) => option.value === selected.project)?.children || [];
  const tasks = categories?.find((option: any) => option.value === selected.category)?.children || [];
  const defaultProjectName = options?.find((option: any) => option.value === defaultSelected?.project)?.label || '';
  const defaultCategoryName = defaultSelected?.category || '';

  const [projectsQuery, setProjectsQuery] = React.useState(defaultProjectName);
  const [categoriesQuery, setCategoriesQuery] = React.useState(defaultCategoryName);
  const [tasksQuery, setTasksQuery] = React.useState('');

  let projectsToShow = options
    ?.filter((option: any) => option.label.toLowerCase().includes(projectsQuery.toLowerCase()))
    .sort((a: any, b: any) => {
      const aIndex = recentProjects.indexOf(a.value);
      const bIndex = recentProjects.indexOf(b.value);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    }) || [];

  const categoriesToShow = categories.filter((option: any) => option.label.toLowerCase().includes(categoriesQuery.toLowerCase()))
  const tasksToShow = tasks.filter((option: any) => option.label.toLowerCase().includes(tasksQuery.toLowerCase()))

  return (
    <Modal
      size="100%" opened={opened} fullScreen={isMobile} withCloseButton onClose={() => {
        onClose();
        setSelected(defaultSelected);
      }}>
      <div className="flex flex-col md:flex-row w-full ">
        <div className="flex flex-col md:mx-1 w-full md:w-1/3 max-h-[700px]">
          <h2>Project</h2>

          <Input placeholder="Search for client or project" defaultValue={defaultProjectName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProjectsQuery(event.target.value)
          }} />

          <div className="h-full w-full overflow-auto flex flex-row md:flex-col md:mb-12" style={{ maxHeight: '900px' }}>
            {projectsToShow.map((option: any) => {
              const isRecentProject = recentProjects.includes(option.value);
              return (
                <Option
                  key={option.value}
                  onClick={() => {
                    if (option.children.length > 1) {
                      setSelected({ project: option.value })
                    } else {
                      setSelected({ project: option.value, category: option.children[0]?.value || undefined })
                    }
                  }}
                  isSelected={selected.project === option.value}
                  label={option.label}
                  recentProject={isRecentProject}
                />
              )
            })}
          </div>
        </div>
        <div className="flex flex-col md:mx-1 w-full md:w-1/3 max-h-[700px]">
          <h2>Category</h2>
          <Input placeholder="Search for category" defaultValue={defaultCategoryName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCategoriesQuery(event.target.value)
          }} />
          <div className="h-full w-full overflow-auto flex flex-row md:flex-col md:mb-12" style={{ maxHeight: '900px' }}>
            {categories.length > 0 ? categoriesToShow.map((option: any) => {
              return (
                <Option
                  key={option.value}
                  onClick={() => setSelected({ project: selected.project, category: option.value })}
                  isSelected={selected.category === option.value}
                  label={option.label}
                />
              )
            }) : (
              <div className="flex justify-center m-2">
                <h3>
                  Select a project to see categories
                </h3>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col md:mx-1 w-full md:w-1/3 max-h-[700px]">
          <h2>Task</h2>
          <Input placeholder="Search for task" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTasksQuery(event.target.value)
          }} />

          <div className="h-full w-full overflow-auto flex flex-row md:flex-col md:mb-12" style={{ maxHeight: '900px' }}>
            {tasks.length > 0 ? tasksToShow.map((option: any) => {
              return (
                <Option
                  disabled={option.disabled}
                  key={option.value}
                  onClick={() => setSelected({ ...selected, task: option.value })}
                  isSelected={selected.task === option.value}
                  label={option.label}
                />
              )
            }) : (
              <div className="flex justify-center m-2">
                <h3>
                  Select a category to see tasks
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end md:absolute bottom-0 right-0 mt-2 md:m-2 pointer-events-none">
        <Button
          disabled={!selected.task}
          loading={isLoading} size="lg" className="flex justify-end pointer-events-auto" onClick={async () => {
            setIsLoading(true)
            await onSelect(selected);
            setIsLoading(false)
            setTimeout(() => {
              onClose();
              setSelected(defaultSelected);
              setProjectsQuery(defaultProjectName);
              setCategoriesQuery(defaultCategoryName);
              setTasksQuery('');
            }, 200);
          }}>Add Task</Button>
      </div>
    </Modal>
  );
};

export default TaskSelectorModal; 