import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Statistic, Button } from 'antd';
import Tag from '../../common/components/Tag';
import TimesheetListActions from './TimesheetListActions';
import { getColorByStatus } from '../../common';
import { CANCEL_TIMESHEET_TIMESHEET_LINEITEM } from '../../project-manager/mutations';
import { GET_TIMESHEETS_FOR_USER } from '../queries';
import TimesheetLoading from './TimesheetLoading';
import classNames from 'classnames';
import { DELETE_TIMESHEET } from '../mutations';
import { getCurrentWeekEndDate } from '../../common';
import TimesheetApprovers from '../../project-manager/components/TimesheetApprovers';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

const TimesheetListContainer = ({ startDate, endDate, setRange }) => {
  const [global] = useGlobal();
  const weekEndDay = get(global, 'config.["week-end-day"]');
  const history = useHistory();
  const [months, setMonths] = useState(null);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const { loading, data, refetch } = useQuery(GET_TIMESHEETS_FOR_USER, {
    variables: { startDate, endDate },
    fetchPolicy: 'cache-and-network',
  });

  const timesheets = get(data, 'me.timesheets');
  const [cancelTimesheet] = useMutation(CANCEL_TIMESHEET_TIMESHEET_LINEITEM, {
    refetchQueries: [{ query: GET_TIMESHEETS_FOR_USER, variables: { startDate, endDate } }],
    awaitRefetchQueries: true,
  });
  const [deleteTimesheet] = useMutation(DELETE_TIMESHEET, {
    refetchQueries: [{ query: GET_TIMESHEETS_FOR_USER, variables: { startDate, endDate } }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (timesheets) {
      const startMoment = moment(startDate, 'YYYYMMDD');
      const endMoment = moment(endDate, 'YYYYMMDD');
      const numOfMonths = endMoment.diff(startMoment, 'months') + 1;
      const _months = [...new Array(numOfMonths)].map((_, index) => {
        const monthEndMoment = new moment(endMoment).subtract(index, 'month');
        const year = monthEndMoment.format('YYYY');
        const number = monthEndMoment.format('MM');
        const weeks = [];

        const dayMapping = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
        let timesheetEndDate = moment()
          .year(year)
          .month(Number(number) - 1)
          .date(1);

        timesheetEndDate = timesheetEndDate.day(dayMapping[weekEndDay]);

        // Get the actual days in the month
        const daysInMonth = monthEndMoment.daysInMonth();
        const lastDayOfMonth = moment().year(year).month(Number(number) - 1).date(daysInMonth);
        
        // Calculate the number of weeks needed to cover the entire month
        const firstWeekEnd = timesheetEndDate.clone();
        const lastWeekEnd = lastDayOfMonth.clone().day(dayMapping[weekEndDay]);
        if (lastWeekEnd.isBefore(lastDayOfMonth)) {
          lastWeekEnd.add(7, 'days');
        }
        
        const numWeeks = Math.ceil(lastWeekEnd.diff(firstWeekEnd, 'days') / 7) + 1;

        weeks.push(
          ...[...Array(numWeeks)]
            .map((_, i) => {
              const currentDate = timesheetEndDate?.clone().add(i * 7, 'days');
              if (currentDate?.format('YYYYMM') === monthEndMoment.format('YYYYMM')) {
                const endDate = currentDate?.format('YYYYMMDD');
                return {
                  endDate,
                  timesheet: timesheets.find(t => t.endDate === endDate),
                };
              }
              return null;
            })
            .filter(Boolean),
        );

        return {
          name: monthEndMoment.format('MMMM'),
          number,
          year,
          weeks: weeks.sort((a, b) => b.endDate - a.endDate),
        };
      });
      setMonths(_months);
      setInitialLoaded(true);
    }
  }, [timesheets, endDate, startDate, weekEndDay]);

  // Load More Function
  const loadMore = () => {
    const newMomentStartDate = moment(startDate, 'YYYYMMDD').subtract(1, 'month');
    setRange({
      startDate: newMomentStartDate,
      endDate: moment(endDate, 'YYYYMMDD'),
    });
  };

  if (loading && !initialLoaded) {
    return <TimesheetLoading />;
  }

  return (
    <div>
      {months &&
        months.map(({ year, name, weeks }, index) => {
          return (
            <div key={index}>
              <div className="text-2xl font-medium mb-2">{`${name} ${year}`}</div>
              <div className="flex flex-wrap mb-4">
                {weeks.map(week => {
                  const currentWeek = getCurrentWeekEndDate() === week.endDate;
                  const overdue = moment(week.endDate, 'YYYYMMDD').isBefore(moment(), 'day');

                  if (week.timesheet) {
                    const { timesheet } = week;
                    const { _id, endDate, status, totalHours, approvalsRequired } = timesheet;
                    return (
                      <div key={_id} className="p-1 w-full md:w-1/2 lg:w-1/3">
                        <div
                          className={classNames(
                            'relative bg-white p-4 rounded-[5px] h-64 hover:shadow-lg shadow-md transition-shadow duration-200 overflow-hidden',
                            // {
                            //   'border-[1px] border-solid border-gray-400': !currentWeek,
                            //   'border-[1px] border-gray-500 border-solid': currentWeek,
                            // },
                          )}
                        >
                          {currentWeek && (
                            <div className="absolute bottom-0 left-0 p-1 bg-gray-500 text-xs text-white rounded-tr-[5px]">
                              Current Week
                            </div>
                          )}
                          {overdue && timesheet.status !== 'Approved' && (
                            <div className="absolute bottom-0 right-0 p-1 bg-red-200 text-xs text-white rounded-tl-[5px]">
                              Overdue
                            </div>
                          )}
                          <div className="flex justify-between">
                            <div>
                              <Tag color={getColorByStatus(status)}>{status}</Tag>
                            </div>
                            <TimesheetListActions
                              timesheet={timesheet}
                              cancel={() =>
                                cancelTimesheet({
                                  variables: { timesheetId: _id },
                                })
                              }
                              deleteTimesheet={() => {
                                deleteTimesheet({
                                  variables: { timesheetId: _id },
                                });
                              }}
                              refetch={refetch}
                            />
                          </div>
                          <div className="flex justify-between">
                            <div className="text-lg text-gray-800">
                              {moment(endDate, 'YYYYMMDD').format('LL')}
                            </div>
                          </div>
                          <div className="flex justify-between py-2">
                            <Statistic
                              title="Total Hours"
                              value={numeral(totalHours).format('0.[00]')}
                            />
                            <div>
                              <div className="">
                                {approvalsRequired?.length === 0 ? (
                                  <Statistic
                                    title="Approvals Needed"
                                    value={
                                      approvalsRequired.length === 0
                                        ? 'None'
                                        : approvalsRequired.filter(a => a.approvedBy).length
                                    }
                                    suffix={
                                      approvalsRequired.length === 0
                                        ? null
                                        : `/ ${approvalsRequired.length}`
                                    }
                                  />
                                ) : (
                                  <div>
                                    <div
                                      className="text-right"
                                      style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                                    >
                                      Approvals Needed
                                    </div>
                                    <div className="flex flex-row flex-wrap justify-end gap-2 my-3">
                                      <TimesheetApprovers approvalsRequired={approvalsRequired} />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <Statistic
                              title="Approvals Needed"
                              value={
                                approvalsRequired.length === 0 ? 'None' : approvalsRequired.filter(a => a.approvedBy).length
                              }
                              suffix={
                                approvalsRequired.length === 0
                                  ? null
                                  : `/ ${approvalsRequired.length}`
                              }
                            /> */}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div key={week.endDate} className="p-1 w-full md:w-1/2 lg:w-1/3">
                      <div
                        className={classNames(
                          'relative p-4 rounded-md h-64 shadow-inner bg-gray-200 overflow-hidden',
                          {
                            'border-[1px] border-dashed border-gray-400': !currentWeek,
                            'border-[1px] border-gray-500 border-solid': currentWeek,
                          },
                        )}
                      >
                        {currentWeek && (
                          <div className="absolute bottom-0 left-0 p-1 bg-gray-500 text-xs text-white rounded-tr-md">
                            Current Week
                          </div>
                        )}
                        {overdue && (
                          <div className="absolute bottom-0 right-0 p-1 bg-red-200 text-xs text-white rounded-tl-md">
                            Overdue
                          </div>
                        )}
                        <div className="text-lg text-gray-800">
                          {moment(week.endDate, 'YYYYMMDD').format('LL')}
                        </div>
                        <div className="h-full flex items-center flex-col">
                          <div className="text-base text-gray-700 mb-4 mt-12">
                            No timesheet exists for this week
                          </div>
                          <Button
                            type="primary"
                            disabled={loading}
                            onClick={() => history.push(`/user/timesheets/${week.endDate}`)}
                          >
                            Open
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      {initialLoaded && (
        <div className="flex justify-center mt-4">
          <Button loading={loading} onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(TimesheetListContainer);
