import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { CalendarTaskProps } from './types';
import moment from 'moment';
import Popconfirm from '../../../common/components/Popconfirm';

const CalendarTask: React.FC<CalendarTaskProps> = ({
  task,
  pixelsPerHour,
  position,
  totalPositions,
  onMouseDown,
  onDelete,
  onUpdate,
  useTimeBasedPositioning = false,
  dayStart = 0,
  dayEnd = 24,
  onEditModalOpenChange,
  isLocked = false,
  onEventClick,
}) => {
  const timeIncrement = 0.25;
  const [isEditing, setIsEditing] = useState(false);
  const [currentEndTime, setCurrentEndTime] = useState(Math.round(task.endTime / timeIncrement) * timeIncrement);
  const [currentStartTime, setCurrentStartTime] = useState(Math.round(task.startTime / timeIncrement) * timeIncrement);
  const [startY, setStartY] = useState<number | null>(null);
  const [isAdjustingStart, setIsAdjustingStart] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isVerticalDragging, setIsVerticalDragging] = useState(false);
  const [dragStartY, setDragStartY] = useState<number | null>(null);

  // Update local state when task prop changes
  useEffect(() => {
    setCurrentStartTime(Math.round(task.startTime / timeIncrement) * timeIncrement);
    setCurrentEndTime(Math.round(task.endTime / timeIncrement) * timeIncrement);
  }, [task.startTime, task.endTime, timeIncrement]);

  // Calculate width and left position for side-by-side display
  const taskWidth = useTimeBasedPositioning
    ? totalPositions > 1 ? `${100 / totalPositions}%` : '100%'
    : '100%';

  const taskLeft = useTimeBasedPositioning
    ? totalPositions > 1 ? `${(position * 100) / totalPositions}%` : '0'
    : '0';

  const handleDragStart = (e: React.DragEvent) => {
    if (isEditing || isVerticalDragging || isLocked) {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', JSON.stringify({
      ...task,
      date: task.date || moment().format('YYYYMMDD')
    }));
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = () => {
    // No-op since we don't need to do anything on drag end
  };

  const handleMouseDown = (e: React.MouseEvent, isStart: boolean = false) => {
    if (isLocked) return;

    e.stopPropagation();
    setIsEditing(true);
    setIsAdjustingStart(isStart);
    setStartY(e.clientY);
    // Notify parent component we're starting to edit
    onMouseDown(e, task);
  };

  const handleTaskMouseDown = (e: React.MouseEvent) => {
    if (isLocked) return;

    e.stopPropagation();
    setIsVerticalDragging(true);
    setDragStartY(e.clientY);
    setIsEditing(true);
    onMouseDown(e, task);
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isEditing && startY !== null) {
        const deltaY = e.clientY - startY;
        const deltaHours = deltaY / pixelsPerHour;

        if (isAdjustingStart) {
          const newStartTime = Math.round((task.startTime + deltaHours) / timeIncrement) * timeIncrement;
          setCurrentStartTime(Math.min(currentEndTime - timeIncrement, Math.max(dayStart, newStartTime)));
        } else {
          const originalDuration = task.endTime - task.startTime;
          const newEndTime = Math.round((task.startTime + originalDuration + deltaHours) / timeIncrement) * timeIncrement;
          setCurrentEndTime(Math.max(currentStartTime + timeIncrement, Math.min(dayEnd, newEndTime)));
        }
      } else if (isVerticalDragging && dragStartY !== null) {
        const deltaY = e.clientY - dragStartY;
        const deltaHours = deltaY / pixelsPerHour;

        const newStartTime = Math.round((task.startTime + deltaHours) / timeIncrement) * timeIncrement;
        const newEndTime = Math.round((task.endTime + deltaHours) / timeIncrement) * timeIncrement;

        // Ensure the task stays within day bounds
        if (newStartTime >= dayStart && newEndTime <= dayEnd) {
          setCurrentStartTime(newStartTime);
          setCurrentEndTime(newEndTime);
        }
      }
    };

    const handleMouseUp = () => {
      if (isEditing || isVerticalDragging) {
        const updatedTask = {
          ...task,
          startTime: currentStartTime,
          endTime: currentEndTime,
        };
        onUpdate(updatedTask);
        setIsEditing(false);
        setIsAdjustingStart(false);
        setStartY(null);
        setIsVerticalDragging(false);
        setDragStartY(null);
      }
    };

    if (isEditing || isVerticalDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isEditing, isVerticalDragging, isAdjustingStart, startY, dragStartY, task, currentStartTime, currentEndTime, pixelsPerHour, onUpdate, timeIncrement, dayStart, dayEnd]);

  const roundedStartTime = Math.round(currentStartTime / timeIncrement) * timeIncrement;
  const roundedEndTime = Math.round(currentEndTime / timeIncrement) * timeIncrement;
  const duration = roundedEndTime - roundedStartTime;

  const isOutlookEvent = task.data.isOutlookEvent;

  return (
    <div
      className={classNames('overflow-hidden task-container transition-all duration-100 ease-in-out', {
        'py-1 first:pt-2': !useTimeBasedPositioning,
      })}
      style={{
        height: `${duration * pixelsPerHour}px`,
        zIndex: 2,
        opacity: isEditing ? 0.5 : task.opacity,
        pointerEvents: isEditing || isLocked ? 'none' : 'auto',
        position: useTimeBasedPositioning ? 'absolute' : undefined,
        top: useTimeBasedPositioning ? `${(roundedStartTime - dayStart) * pixelsPerHour}px` : undefined,
        width: taskWidth,
        left: taskLeft,
      }}
    >
      <div
        className={classNames('relative overflow-hidden h-full border border-[1px] border-solid border-gray-500', {
          'cursor-[ns-resize]': !isOutlookEvent && !isEditing && useTimeBasedPositioning,
          'cursor-default': !isOutlookEvent && !useTimeBasedPositioning,
          'cursor-pointer': isOutlookEvent && onEventClick,
        })}
        onMouseDown={(e) => {
          if (isOutlookEvent) {
            e.stopPropagation();
          }

          if (useTimeBasedPositioning && !isOutlookEvent) {
            handleTaskMouseDown(e);
          }
        }}
        onClick={(e) => {
          if (isOutlookEvent && onEventClick) {
            onEventClick(task);
          }
        }}
        draggable={!isEditing && !isVerticalDragging && !isOutlookEvent}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div
          className='px-1  h-full relative'
          style={{
            backgroundColor: task.color,
          }}
        >
          {/* Top handle */}
          {
            useTimeBasedPositioning && !isOutlookEvent && (
              <div
                className="absolute w-full left-0 right-0 top-0 h-[15px] flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity"
                onMouseDown={(e) => {
                  handleMouseDown(e, true);
                }}
                style={{
                  cursor: 'grab',
                }}
              >
                <div
                  className="w-full mx-auto max-w-[50%] h-[5px] bg-white rounded-full"
                >
                </div>
              </div>
            )
          }
          {useTimeBasedPositioning && (
            <div className="text-xs font-medium">
              {moment().startOf('day').add(roundedStartTime, 'hours').format('h:mma').replace(':00', '')} - {moment().startOf('day').add(roundedEndTime, 'hours').format('h:mma').replace(':00', '')}
            </div>
          )}
          <div>{`${duration.toFixed(2)}hr`}</div>
          <div className="text-xs">{task.title}</div>
          {isHovering && !isOutlookEvent && (
            <div className="absolute top-0 right-0 flex gap-0.5" style={{
              backgroundColor: task.color,
            }}>
              <div
                className="cursor-pointer p-1 transition-opacity duration-200"
                onClick={(e) => {
                  e.stopPropagation();
                  onEditModalOpenChange(true, task);
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-700"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </div>
              <div
                className="cursor-pointer p-1 transition-opacity duration-200"
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to delete this task?"
                  onConfirm={async () => {
                    setIsEditing(true);
                    await onDelete(task);
                    setIsEditing(false);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </Popconfirm>
              </div>
            </div>
          )}
          {/* Bottom handle */}
          {!isOutlookEvent && (
            <div
              className="absolute w-full left-0 right-0 bottom-0 h-[15px] flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity"
              onMouseDown={(e) => {
                handleMouseDown(e, false);
              }}
              style={{
                cursor: 'grab',
              }}
            >
              <div
                className="w-full mx-auto max-w-[50%] h-[5px] bg-white rounded-full"
              >
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarTask;