import { useState } from 'react';
import { useGlobal } from 'reactn';
import { Query } from 'react-apollo';
import moment from 'moment';
import { Tag, Button, Input } from 'antd';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import Profile from '../../common/components/Profile';
import Modal from '../../common/components/Modal';
import UpdateResourceForm from '../../common/components/UpdateResourceForm';
import ProjectDetailsResourceActions from './ProjectDetailsResourceActions';
import { GET_RESOURCES_BY_PROJECT_ID } from '../queries';
import ProjectResourceHours from './ProjectResourceHours';
import { GET_PROJECT_RESOURCE_HOURS } from './ProjectResourceHours';
import classNames from 'classnames';

const downloadCSV = (data) => {
  const headers = ['Name', 'Role', 'Email', 'Start Date', 'End Date', 'Note', 'Status'];
  const csvData = data.map(resource => [
    `${resource.user?.firstName || ''} ${resource.user?.lastName || ''}`,
    resource.role || 'Not defined',
    resource.email || '',
    resource.startDate ? moment(resource.startDate).format('M/D/YY') : 'Not set',
    resource.endDate ? moment(resource.endDate).format('M/D/YY') : 'Not set',
    resource.note || '',
    resource.inactive ? 'Inactive' : 'Active'
  ]);

  const csvContent = [
    headers.join(','),
    ...csvData.map(row => row.map(cell => `"${cell}"`).join(','))
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `project-resources-${moment().format('YYYY-MM-DD')}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ProjectDetailsResourceList = ({ projectId, roles, isPaginated = true }) => {
  const [lockEditing] = useGlobal('lockEditing');
  const [showModal, setShowModal] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <Card
      actionComponent={
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Input
            placeholder="Search by name"
            value={searchText}
            onChange={e => handleSearch(e.target.value)}
            style={{ width: 200 }}
            allowClear
          />
          <Button 
            onClick={() => filteredData && downloadCSV(filteredData)}
            icon="download"
          >
            Export to CSV
          </Button>
          <Button disabled={lockEditing} onClick={() => setShowModal(true)}>
            Add Resource
          </Button>
        </div>
      }
    >
      <Query query={GET_RESOURCES_BY_PROJECT_ID} variables={{ projectId }}>
        {({ loading, error, data }) => {
          if (error) throw new Error(error);
          const resources = data?.projectManagement?.project?.resources || [];
          
          // Filter resources based on search text
          const filteredResources = resources.filter(resource => {
            if (!searchText) return true;
            
            const searchString = [
              resource.user?.firstName,
              resource.user?.lastName
            ].filter(Boolean).join(' ').toLowerCase();
            
            return searchString.includes(searchText.toLowerCase());
          });

          const columns = [
            {
              title: 'Resource',
              key: 'resource',
              width: '30%',
              render: ({ user, role, email, allowProjectManagement, timesheetApprover, ...rest }) => {
                const { firstName, lastName, imageUrl } = user || {};
                return (
                  <Profile
                    url={
                      rest?.integration?.sourceId
                        ? `https://3862939.app.netsuite.com/app/accounting/project/allocation.nl?id=${rest.integration.sourceId}&e=T&l=T`
                        : null
                    }
                    allowProjectManagement={allowProjectManagement}
                    timesheetApprover={timesheetApprover}
                    imageUrl={imageUrl}
                    title={role || 'Role not defined'}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                  />
                );
              },
              sorter: (a, b) => {
                const nameA = `${a.user?.firstName} ${a.user?.lastName}`;
                const nameB = `${b.user?.firstName} ${b.user?.lastName}`;
                return nameA.localeCompare(nameB);
              },
              filters: [
                ...roles.map(role => ({
                  text: role.name,
                  value: role.name,
                })),
              ],
              onFilter: (value, record) => {
                // Check for project manager filter
               
                // Then check if there's an active role filter
                return record.role === value;
              },
            },
            {
              title: 'Duration',
              key: 'dateRange',
              render: (_, record) => {
                if (!record.startDate && !record.endDate) {
                  return <div className="text-gray-500">Not Set</div>;
                }
                const startDate = record.startDate ? moment(record.startDate).format('M/D/YY') : undefined;
                const endDate = record.endDate ? moment(record.endDate).format('M/D/YY') : undefined;

                return (
                  <div>
                    <span className={classNames({ 'text-gray-500': !startDate })}>{startDate || 'Not set'}</span>
                    {' - '}
                    <span className={classNames({ 'text-gray-500': !endDate })}>{endDate || 'Not set'}</span>
                  </div>
                );
              },
            },
            {
              title: 'Aprv. / Allocated Hours',
              dataIndex: 'budgetedHours',
              width: 200,
              key: 'budgetedHours',
              render: (data, resource) => {
                return <ProjectResourceHours resourceId={resource._id} />;
              },
            },
            {
              title: 'Note',
              width: 300,
              dataIndex: 'note',
              key: 'note',
            },
            {
              title: 'Inactive',
              width: 100,
              dataIndex: 'inactive',
              key: 'inactive',
              render: data => data && <Tag color="red">Yes</Tag>,
            },
            {
              title: 'Actions',
              key: 'actions',
              render: data => (
                <ProjectDetailsResourceActions
                  roles={roles}
                  resource={data}
                  refetchQueries={[
                    {
                      query: GET_RESOURCES_BY_PROJECT_ID,
                      variables: {
                        projectId,
                      },
                    },
                    {
                      query: GET_PROJECT_RESOURCE_HOURS,
                      variables: {
                        resourceId: data._id,
                      },
                    },
                  ]}
                />
              ),
            },
          ];

          return (
            <div>
              <Modal
                title="Add Resource"
                visible={showModal}
                destroyOnClose
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={null}
              >
                <UpdateResourceForm
                  projectId={projectId}
                  roles={roles}
                  refetchQueries={[
                    {
                      query: GET_RESOURCES_BY_PROJECT_ID,
                      variables: {
                        projectId,
                      },
                    },
                  ]}
                  onComplete={() => setShowModal(false)}
                />
              </Modal>
              <Table
                size="small"
                columns={columns}
                loading={loading}
                pagination={isPaginated}
                dataSource={filteredResources.map(r => ({ ...r, key: r._id }))}
                onChange={(pagination, filters, sorter, { currentDataSource }) => {
                  setFilteredData(currentDataSource);
                }}
              />
            </div>
          );
        }}
      </Query>
    </Card>
  );
};

export default ProjectDetailsResourceList;
