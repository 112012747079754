import { BlobServiceClient } from '@azure/storage-blob';
import moment from 'moment';
import { getGlobal } from 'reactn';
import { get } from 'lodash';

// eslint-disable-next-line
export const getColorByStatus = status => {
  switch (status) {
    case 'Submitted-Pending':
      return '#F2C94C';
    case 'Declined':
      return '#EB5757';
    case 'Approved':
      return '#27AE60';
    default:
      return '#828282';
  }
};

export const capitalize = s => get(s, '[0]') && get(s, '[0]').toUpperCase() + s.slice(1);

export async function uploadFileToAzureBlob({ fileName, file, blobContainerName, sasToken }) {
  const { REACT_APP_AZURE_STORAGE_ACCOUNT, REACT_APP_AZURE_STORAGE_SAS } = process?.env;
  const storageUrl = `https://${REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net`;
  let blobServiceClient;
  if (sasToken) {
    // new approach, using token with shorter expiration
    blobServiceClient = new BlobServiceClient(
      `https://${REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${sasToken}`,
    );
  } else {
    // backwards compatible with using the token stored in frontend .env
    blobServiceClient = new BlobServiceClient(
      `https://${REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${REACT_APP_AZURE_STORAGE_SAS}`,
    );
  }
  const containerClient = blobServiceClient.getContainerClient(blobContainerName);
  const blockBlobClient = containerClient.getBlockBlobClient(fileName);
  await blockBlobClient.uploadBrowserData(file);
  return `${storageUrl}/${blobContainerName}/${fileName}`;
}

export const getWeekDays = () => {
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const { config } = getGlobal();
  const weekEndDay = config['week-end-day'];

  // if url endDate does not match to redux state endDate, update state to match URL endDate
  let weekStartIndex = days.indexOf(weekEndDay);
  if (weekStartIndex < 0) {
    throw new Error('Invalid weekend day in config');
  }
  const reorderedDays = [...days.slice(weekStartIndex + 1), ...days.slice(0, weekStartIndex + 1)];
  return reorderedDays;
};

export const getCurrentWeekEndDate = () => {
  const { config } = getGlobal();
  const weekEndDay = config['week-end-day'];
  const today = moment();
  const dayMapping = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
  const targetDay = dayMapping[weekEndDay];
  const currentDay = today.day();
  
  // Calculate days until next week end day
  let daysUntilWeekEnd = (targetDay - currentDay + 7) % 7;
  if (daysUntilWeekEnd === 0 && today.isAfter(today.clone().day(targetDay))) {
    daysUntilWeekEnd = 7;
  }
  
  const endDate = today.clone().add(daysUntilWeekEnd, 'days');
  return endDate.format('YYYYMMDD');
};
