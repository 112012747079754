import classNames from 'classnames';
import numeral from 'numeral';
import { Tooltip } from '@mantine/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_MY_ASSIGNED_DELIVERABLE = gql`
    query GetMyAssignedHours($deliverableId: String!) {
        me {
            _id
            myDeliverableAssignment(deliverableId: $deliverableId) {
                budgetedHours
                remainingHours
            }
        }
    }
`;

const AssignedResourceIndicator = ({ deliverable }: { user: any, deliverable: any }) => {
    const { data, loading } = useQuery(GET_MY_ASSIGNED_DELIVERABLE, {
        variables: { deliverableId: deliverable._id },
        fetchPolicy: 'cache-and-network',
    });

    const hours = {
        budgeted: data?.me?.myDeliverableAssignment?.budgetedHours,
        remaining: data?.me?.myDeliverableAssignment?.remainingHours,
    };

    if (loading || !data || !hours.budgeted) return null;

    return <Tooltip label="Your remaining hours vs budgeted hours for this deliverable">
        <div className="text-sm text-gray-500">
            <span className={classNames('font-bold', {
                'text-red-300': hours.remaining <= 0,
            })}>{numeral(hours.remaining).format('0.00')} / {numeral(hours.budgeted).format('0.00')}</span>
        </div>
    </Tooltip>;
};

export default AssignedResourceIndicator;
