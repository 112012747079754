import { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal, NumberInput, Textarea, Group, Select, Tooltip } from '@mantine/core';
import randomColor from 'randomcolor';
import CalendarDay from './CalendarDay';
import TaskSelectorModal from '../../../common/components/TaskSelector/TaskSelectorModal';
import { constructOptions } from '../TimesheetGridRowAddNew';
import { GET_AVAILABLE_DELIVERABLES } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import { useGlobal } from 'reactn';
import { GET_USER_TIMESHEET } from '../../queries';
import client from '../../../../api/graphql/client';
import { UPDATE_HOURS_MUTATION, ADD_HOURS_MUTATION, ADD_TIMESHEET_LINEITEM, UPDATE_TIMESHEET_TASK_COMMENT } from '../../mutations';
import { showError } from '../../../common/notifications';
import { showSuccess } from '../../../common/notifications';
import { errorMessage } from '../TimesheetGridRowHour';
import { IGlobalState, ProjectLegend, TimesheetCalendarProps, Task } from './types';
import { fetchOutlookEvents, convertOutlookEventToTask } from './outlookCalendar';

const generateDates = (startDate: string, endDate: string) => {
  let dates = [];
  let currentDate = moment(startDate);

  while (currentDate <= moment(endDate)) {
    dates.push(moment(currentDate));
    currentDate = moment(currentDate).add(1, 'days');
  }

  return dates;
};

const EditTaskModal: React.FC<{
  opened: boolean;
  onClose: () => void;
  task: Task | null;
  onSave: (task: Task) => void;
  view: 'Calendar' | 'Blocks';
}> = ({ opened, onClose, task, onSave, view }) => {
  const [comment, setComment] = useState(task?.data?.comment || '');
  const [startTime, setStartTime] = useState(task?.startTime || 0);
  const [duration, setDuration] = useState(task ? task.endTime - task.startTime : 0);

  // Update state when task changes
  useEffect(() => {
    if (task) {
      setComment(task.data?.comment || '');
      setStartTime(task.startTime);
      setDuration(task.endTime - task.startTime);
    }
  }, [task]);

  // Generate time options for the dropdown
  const timeOptions = Array.from({ length: 96 }, (_, i) => {
    const hour = i / 4; // 0.25 increments
    const value = hour.toString();
    const label = moment().startOf('day').add(hour, 'hours').format('h:mm A');
    return { value, label };
  });

  const handleSave = () => {
    if (!task?.data?.projectId || !task?.data?.deliverableId || !task?.data?.hours) {
      return;
    }

    const updatedHours = startTime + duration - startTime;

    onSave({
      ...task,
      startTime,
      endTime: startTime + duration,
      data: {
        projectId: task.data.projectId,
        deliverableId: task.data.deliverableId,
        hours: updatedHours,
        comment,
        taskId: task.data.taskId,
      },
    });
    onClose();
  };

  if (!task) return null;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Edit Task"
      size="md"
      styles={{
        content: {
          minWidth: '400px'
        }
      }}
    >
      <div className="space-y-4 z-[1000]">
        <div className="text-sm font-medium text-gray-700">{task.title}</div>
        <div className="grid grid-cols-2 gap-4">
          {view === 'Calendar' && (
            <Select
              label="Start Time"
              value={startTime ? startTime.toString() : undefined}
              onChange={(value) => setStartTime(parseFloat(value || '0'))}
              data={timeOptions}
              searchable
              withinPortal
              dropdownPosition="bottom"
              styles={{
                dropdown: {
                  maxHeight: '200px',
                  overflowY: 'auto'
                }
              }}
            />
          )}
          <NumberInput
            label="Duration (hours)"
            value={duration}
            precision={2}
            onChange={(value) => setDuration(value || 0)}
            step={0.25}
            min={0.25}
            max={24}
          />
        </div>
        <Textarea
          label="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Group position="right" mt="md">
          <Button variant="light" onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </div>
    </Modal>
  );
};

const DEFAULT_PIXELS_PER_HOUR = 50;

export default function TimesheetCalendar({ startDate, endDate, timesheetId, lineItems, view, isLocked = false, maxHeight = null }: TimesheetCalendarProps) {
  const [stateUser] = useGlobal<IGlobalState>('user');
  const user = stateUser;
  const [config] = useGlobal<IGlobalState>('config');
  const showImportOutlookEvents = config['calendar-outlook-import'] === 'true' || false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTask, setNewTask] = useState<Task | null>(null);
  const [hoveredProjectId, setHoveredProjectId] = useState<string | null>(null);
  const [draggedTask, setDraggedTask] = useState<Task | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [outlookEvents, setOutlookEvents] = useState<Task[]>([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [dayStart] = useState(0);
  const [dayEnd] = useState(24);
  const [pixelsPerHour, setPixelsPerHour] = useState(DEFAULT_PIXELS_PER_HOUR); // Base pixels per hour
  const { data: deliverablesData } = useQuery(GET_AVAILABLE_DELIVERABLES, {
    variables: { user: user._id, endDate },
  });
  const showZoomControls = view === 'Calendar';

  // Reset pixelsPerHour when switching to Blocks view
  useEffect(() => {
    if (view === 'Blocks') {
      setPixelsPerHour(DEFAULT_PIXELS_PER_HOUR);
    }
  }, [view]);

  const dates = generateDates(startDate, endDate);
  const weekDay = moment(newTask?.date).format('ddd').toLowerCase();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const selectedDeliverableIds = lineItems.reduce((acc, li) => {
    if (li[weekDay + 'Task']) {
      return [...acc, li.deliverable._id];
    }

    return acc;
  }, []);

  // Get unique projects and their colors for the legend, only including projects with tasks
  const projectLegend = lineItems.reduce((acc: ProjectLegend[], item) => {
    const projectId = item.project._id;
    // Check if this line item has at least one task
    const hasTask = Object.keys(item).some(key => key.endsWith('Task') && item[key]);

    if (hasTask && !acc.find(p => p.id === projectId)) {
      acc.push({
        id: projectId,
        name: item.project.name,
        color: randomColor({ luminosity: 'light', seed: projectId })
      });
    }
    return acc;
  }, []);

  const handleTaskUpdate = async (task: Task) => {
    if (isLocked) return;

    await client
      .mutate({
        mutation: UPDATE_HOURS_MUTATION,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate,
              user: user._id,
            },
          },
        ],
        variables: {
          taskId: task.data?.taskId,
          hours: task.endTime - task.startTime,
          startTime: view === 'Calendar' ? task.startTime : undefined,
        },
      })
      .catch(() => {
        showError({
          duration: 6,
          message: errorMessage,
        });
      });

    if (task.data?.comment) {
      await client.mutate({
        mutation: UPDATE_TIMESHEET_TASK_COMMENT,
        variables: {
          taskId: task.data.taskId,
          comment: task.data.comment,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate,
              user: user._id,
            },
          },
        ],
      });
    }
  };

  // const handleTaskDragStart = (task: Task) => {
  //   setDraggedTask(task);
  // };

  const handleTaskDragOver = (targetDate: string) => {
    if (isLocked) return;

    if (draggedTask && draggedTask.date !== targetDate) {
      // Add visual feedback when dragging over a valid target
      const targetElement = document.querySelector(`[data-date="${targetDate}"]`);
      if (targetElement) {
        targetElement.classList.add('bg-gray-50');
      }
    }
  };

  const handleTaskDrop = async (task: Task, targetDate: string) => {
    if (isLocked) return;

    if (!task || !task.data?.taskId || !task.data?.deliverableId || !task.data?.projectId) {
      console.error('Invalid task data for drop:', task);
      return;
    }

    try {
      // First, delete the task from its original day
      await client.mutate({
        mutation: UPDATE_HOURS_MUTATION,
        variables: {
          taskId: task.data.taskId,
          hours: 0, // Set hours to 0 to effectively delete
        },
      });

      const res = await client.mutate({
        mutation: ADD_HOURS_MUTATION,
        variables: {
          date: targetDate,
          deliverableId: task.data.deliverableId,
          projectId: task.data.projectId,
          timesheetId,
          hours: task.endTime - task.startTime,
          user: user._id,
          startTime: view === 'Calendar' ? task.startTime : undefined,
        },
      });

      await client.mutate({
        mutation: UPDATE_TIMESHEET_TASK_COMMENT,
        variables: {
          taskId: res.data.addHours,
          comment: task.data.comment,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate,
              user: user._id,
            },
          },
        ],
      });

      showSuccess({ message: `Successfully moved task to ${moment(targetDate).format('MM/DD')}` });
    } catch (error) {
      console.error('Failed to move task:', error);
      showError({
        duration: 6,
        message: 'Failed to move task',
      });
    } finally {
      setDraggedTask(null);
      // Remove visual feedback
      const targetElement = document.querySelector(`[data-date="${targetDate}"]`);
      if (targetElement) {
        targetElement.classList.remove('bg-gray-50');
      }
    }
  };

  const handleImportOutlookEvents = async () => {
    try {
      setIsLoadingEvents(true);
      console.log('Starting Outlook events import...');
      const events = await fetchOutlookEvents(startDate, endDate);
      console.log('Fetched events:', events);
      const tasks = events.map(convertOutlookEventToTask);
      console.log('Converted to tasks:', tasks);
      setOutlookEvents(tasks);
      showSuccess({ message: 'Successfully imported Outlook events' });
    } catch (error) {
      console.error('Error importing Outlook events:', error);
      showError({ message: 'Failed to import Outlook events' });
    } finally {
      setIsLoadingEvents(false);
    }
  };

  const handleZoomIn = () => {
    // Increase pixels per hour by 25%
    setPixelsPerHour(prev => Math.min(prev * 1.25, 400));
  };

  const handleZoomOut = () => {
    // Decrease pixels per hour by 25%
    setPixelsPerHour(prev => Math.max(prev / 1.25, 50));
  };

  // Add keyboard and mouse wheel zoom handlers
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check if Ctrl key is pressed
      if (e.ctrlKey) {
        if (e.key === '+' || e.key === '=') {
          e.preventDefault();
          handleZoomIn();
        } else if (e.key === '-') {
          e.preventDefault();
          handleZoomOut();
        }
      }
    };

    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        if (e.deltaY < 0) {
          handleZoomIn();
        } else {
          handleZoomOut();
        }
      }
    };

    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('wheel', handleWheel);
    };
  }, []); // Empty dependency array since we're using stable functions

  const handleHeaderClick = (dateStr: string) => {
    if (isLocked) return;
    if (selectedDate === dateStr) {
      setSelectedDate(null);
    } else {
      setSelectedDate(dateStr);
    }
  };

  const calculateDayHeight = (dayStart: number, dayEnd: number) => {
    // Calculate total visible hours
    const visibleHours = dayEnd - dayStart;
    // Calculate total height needed using current pixels per hour
    return visibleHours * pixelsPerHour;
  };

  return (
    <div className="w-full mb-4">
      {!isLocked && showImportOutlookEvents && (
        <div className="w-full flex justify-start items-center mb-4">
          <div className="flex fle-rpw justify-between w-full">
            <Tooltip
              label="Outlook events are temporarily displayed on the timesheet and are not saved. They are meant to help you plan your time entries."
              position="top"
              arrowSize={5}
              multiline
              w={220}
              withArrow
            >
              <Button
                onClick={outlookEvents.length > 0 ? () => setOutlookEvents([]) : handleImportOutlookEvents}
                disabled={isLoadingEvents}
                loading={isLoadingEvents}
                variant="outline"
                color="gray"
                size="xs"
              >
                {outlookEvents.length > 0 ? 'Clear Outlook Events' : 'Import Outlook Events'}
              </Button>
            </Tooltip>
            {showZoomControls && (
              <div className="flex gap-2 bg-white p-2" style={{ position: 'fixed', right: '3rem', bottom: '3rem', zIndex: 1000 }}>
                <Tooltip label="Zoom In (Ctrl & +)" position="top">
                  <Button
                    variant="light"
                    size="xs"
                    onClick={handleZoomIn}
                    disabled={pixelsPerHour >= 400}
                    className="flex items-center gap-1"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                  </Button>
                </Tooltip>
                <Tooltip label="Zoom Out (Ctrl & -)" position="top">
                  <Button
                    variant="light"
                    size="xs"
                    onClick={handleZoomOut}
                    disabled={pixelsPerHour <= 50}
                    className="flex items-center gap-1"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                    </svg>
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        {view === 'Blocks' && (
          <div className="text-sm text-gray-600">The Blocks view visualizes your tasks as blocks for each day.</div>
        )}
        {view === 'Calendar' && (
          <div className="text-sm text-gray-600">The Calendar view lets you see your tasks as a weekly calendar and specify the start time of each task. Existing tasks with no start times are displayed at the top of the day.</div>
        )}
      </div>
      <div className="mt-4 flex flex-wrap gap-4 mb-2">
        {projectLegend.map(project => (
          <div
            key={project.id}
            className="flex items-center gap-2 cursor-pointer"
            onMouseEnter={() => setHoveredProjectId(project.id)}
            onMouseLeave={() => setHoveredProjectId(null)}
          >
            <div
              className="w-4 h-4 rounded"
              style={{ backgroundColor: project.color }}
            />
            <span className="text-sm text-gray-700">{project.name}</span>
          </div>
        ))}
        {showImportOutlookEvents && (
          <div className="flex items-center gap-2">
            <div
              className="w-4 h-4 rounded"
              style={{ backgroundColor: '#E5E7EB' }}
            />
            <span className="text-sm text-gray-700">Outlook Events</span>
          </div>
        )}
      </div>

      <div className="relative">
        <div className="flex flex-col">
          {/* Fixed header row */}
          <div className="flex flex-row gap-[1px] bg-gray-400 p-[1px] w-full cursor-pointer">
            {dates.map(date => {
              const dateStr = date.format('YYYY-MM-DD');
              const isSelected = selectedDate === dateStr;
              return (
                <div
                  key={dateStr}
                  className={`transition-all duration-100 ease-in-out ${selectedDate
                    ? isSelected
                      ? 'flex-[1_1_100%] opacity-100'
                      : 'flex-[0_1_0%] opacity-0 overflow-hidden'
                    : 'flex-1 opacity-100'
                    }`}
                  data-date={dateStr}
                >
                  <div
                    className="flex flex-col justify-center items-center p-2 bg-gray-100 border-0 border-b-[1px] border-gray-400 border-solid relative group"
                    onClick={() => handleHeaderClick(dateStr)}
                  >
                    <div className="font-bold">{moment(date).format('MM/DD')}</div>
                    <div className="font-medium uppercase">{moment(date).format('dddd')}</div>
                    <div className="absolute left-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity w-6 flex justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isSelected ? "M9 5l7 7-7 7" : "M15 19l-7-7 7-7"} />
                      </svg>
                    </div>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity w-6 flex justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isSelected ? "M15 19l-7-7 7-7" : "M9 5l7 7-7 7"} />
                      </svg>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Scrollable content */}
          <div
            className="flex flex-row gap-[1px] bg-gray-400 p-[1px] w-full"
            style={{
              ...(maxHeight ? { maxHeight: `${maxHeight}px` } : {}),
              height: maxHeight ? `${Math.min(calculateDayHeight(dayStart, dayEnd), maxHeight)}px` : `${calculateDayHeight(dayStart, dayEnd)}px`,
              overflowY: maxHeight ? 'auto' : 'hidden'
            }}
          >
            {dates.map(date => {
              const dateStr = date.format('YYYY-MM-DD');
              const isSelected = selectedDate === dateStr;
              const tasks = [
                ...lineItems.flatMap(item => {
                  const dayMap: Record<string, any> = {
                    'mon': item.monTask,
                    'tue': item.tueTask,
                    'wed': item.wedTask,
                    'thu': item.thuTask,
                    'fri': item.friTask,
                    'sat': item.satTask,
                    'sun': item.sunTask
                  };

                  const dayOfWeek = date.format('ddd').toLowerCase() as keyof typeof dayMap;
                  const task = dayMap[dayOfWeek];

                  if (!task || !task.hours) return [];

                  const startTime = task.startTime;
                  const endTime = task.startTime + task.hours;

                  return [{
                    startTime,
                    endTime,
                    title: `${item.project.name} - ${item.deliverable.deliverableName}`,
                    color: randomColor({ luminosity: 'light', seed: item.project._id }),
                    opacity: hoveredProjectId && hoveredProjectId !== item.project._id ? 0.25 : 1,
                    data: {
                      projectId: item.project._id,
                      deliverableId: item.deliverable._id,
                      hours: task.hours,
                      comment: task.comment,
                      taskId: task._id
                    }
                  }];
                }),
                ...outlookEvents.filter(event => moment(event.date).format('YYYY-MM-DD') === dateStr)
              ];

              return (
                <div
                  key={dateStr}
                  className={`transition-all duration-100 ease-in-out ${selectedDate
                    ? isSelected
                      ? 'flex-[1_1_100%] opacity-100'
                      : 'flex-[0_1_0%] opacity-0 overflow-hidden'
                    : 'flex-1 opacity-100'
                    }`}
                  data-date={dateStr}
                >
                  <CalendarDay
                    useTimeBasedPositioning={view === 'Calendar'}
                    tasks={tasks}
                    height={`${calculateDayHeight(dayStart, dayEnd)}px`}
                    dayStart={view === 'Calendar' ? dayStart : 0}
                    dayEnd={view === 'Calendar' ? dayEnd : 12}
                    date={date}
                    onNewTask={(tasks: Task[]) => {
                      if (isLocked) return;
                      const newTask = tasks[tasks.length - 1];
                      setNewTask(newTask);
                      setIsModalOpen(true);
                    }}
                    onDeleteTask={(task: Task) => {
                      if (isLocked) return;
                      return handleTaskUpdate({
                        ...task,
                        endTime: task.startTime,
                      });
                    }}
                    onUpdateTask={handleTaskUpdate}
                    onTaskDragOver={handleTaskDragOver}
                    onTaskDrop={handleTaskDrop}
                    onHeaderClick={(date) => {
                      if (isLocked) return;
                      const clickedDateStr = moment(date).format('YYYY-MM-DD');
                      if (selectedDate === clickedDateStr) {
                        setSelectedDate(null);
                      } else {
                        setSelectedDate(clickedDateStr);
                      }
                    }}
                    isEditModalOpen={isEditModalOpen}
                    onEditModalOpenChange={(isOpen, task) => {
                      setIsEditModalOpen(isOpen);
                      if (task) {
                        setEditingTask(task);
                      }
                    }}
                    isLocked={isLocked}
                    isExpanded={isSelected}
                    onEventClick={(task: Task) => {
                      setNewTask(task);
                      setIsModalOpen(true);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

      </div>

      <EditTaskModal
        view={view}
        opened={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setEditingTask(null);
        }}
        task={editingTask}
        onSave={handleTaskUpdate}
      />

      <TaskSelectorModal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        options={constructOptions(deliverablesData?.projects, selectedDeliverableIds)}
        onSelect={async selected => {
          if (newTask) {
            // Check if this is an Outlook event
            const isOutlookEvent = outlookEvents.some(event =>
              event.startTime === newTask.startTime &&
              event.endTime === newTask.endTime &&
              event.title === newTask.title
            );

            await client.mutate({
              mutation: ADD_TIMESHEET_LINEITEM,
              variables: {
                deliverableId: selected.task,
                timesheetId,
                projectId: selected.project,
                user: user._id,
              },
            });

            const addHoursResponse = await client
              .mutate({
                mutation: ADD_HOURS_MUTATION,
                awaitRefetchQueries: true,
                variables: {
                  date: newTask?.date,
                  deliverableId: selected.task,
                  projectId: selected.project,
                  timesheetId,
                  hours: newTask.endTime - newTask.startTime,
                  user: user._id,
                  startTime: view === 'Calendar' ? newTask.startTime : undefined,
                },
                refetchQueries: [
                  {
                    query: GET_USER_TIMESHEET,
                    variables: {
                      endDate,
                      user: user._id,
                    },
                  },
                ],
              })
              .catch(() => {
                showError({
                  duration: 6,
                  message: errorMessage,
                });
              });

            // If this was an Outlook event, add the title to comments and remove it from the list
            if (isOutlookEvent) {
              const taskId = addHoursResponse?.data.addHours;
              await client.mutate({
                mutation: UPDATE_TIMESHEET_TASK_COMMENT,
                variables: {
                  taskId,
                  comment: `${newTask.title}`,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: GET_USER_TIMESHEET,
                    variables: {
                      endDate,
                      user: user._id,
                    },
                  },
                ],
              });

              setOutlookEvents(prevEvents =>
                prevEvents.filter(event =>
                  !(event.startTime === newTask.startTime &&
                    event.endTime === newTask.endTime &&
                    event.title === newTask.title)
                )
              );
            }
          }
        }}
        recentProjects={user?.recentProjects || []}
      />
    </div>
  );
}
