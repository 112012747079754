import React, { useState, useEffect } from 'react';
import { withApollo, useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { Select, Checkbox, InputNumber } from 'antd';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input, { TextArea } from '../../common/components/Input';
import { CREATE_PROJECT } from '../mutations';
import { GET_MY_PROJECTS } from '../../project-manager/queries';
import { GET_CLIENTS, GET_TEMPLATES } from '../queries';

const { Option } = Select;

const NewProjectForm = ({ onComplete, client, notifyUser }) => {
  const [createNewClient, setCreateNewClient] = useState(false);
  const { loading: loadingClients, data } = useQuery(GET_CLIENTS);
  const { loading: loadingTemplates, data: templateData } = useQuery(GET_TEMPLATES);
  const templates = loadingTemplates ? [] : templateData?.templates || [];
  const [clientsToShow, setClientsToShow] = useState([]);

  useEffect(() => {
    setClientsToShow(data?.projectManagement?.clients || []);
  }, [data]);

  return (
    <Formik
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.email = 'Required';
        }
        if (!values.clientName) {
          errors.clientName = 'Required';
        }
        if (!values.clientShortName) {
          errors.clientShortName = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        client
          .mutate({
            mutation: CREATE_PROJECT,
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_MY_PROJECTS,
              },
            ],
            variables: {
              ...values,
            },
          })
          .then(({ data }) => {
            setSubmitting(false);
            onComplete(data?.createProject?._id);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item
            label="Name"
            hasFeedback
            validateStatus={errors.name && touched.name && 'error'}
            help={errors.name && touched.name && errors.name}
            required
          >
            <Input
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Project Code"
            hasFeedback
            validateStatus={errors.projectCode && touched.projectCode && 'error'}
            help={errors.projectCode && touched.projectCode && errors.projectCode}
          >
            <Input
              name="projectCode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.projectCode}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            hasFeedback
            validateStatus={errors.description && touched.description && 'error'}
            help={errors.description && touched.description && errors.description}
          >
            <TextArea
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Template"
            hasFeedback
            help="Select a template to create this project from"
            // validateStatus={errors.existingClientId && touched.existingClientId && 'error'}
            // help={errors.existingClientId && touched.existingClientId && errors.existingClientId}
          >
            <Select
              allowClear
              loading={loadingTemplates}
              placeholder="Select a template"
              // value={values.existingClientId}
              disabled={isSubmitting}
              onChange={value => {
                if (value) {
                  setFieldValue('templateId', value);
                } else {
                  setFieldValue('templateId', null);
                }
              }}
              onBlur={handleBlur}
            >
              {templates.map(template => (
                <Option key={template._id} value={template._id}>
                  {template.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Client"
            required
            hasFeedback
            validateStatus={errors.existingClientId && touched.existingClientId && 'error'}
            help={errors.existingClientId && touched.existingClientId && errors.existingClientId}
          >
            <Select
              allowClear
              showSearch
              loading={loadingClients}
              placeholder="Select an existing client"
              value={createNewClient ? null : values.existingClientId}
              disabled={isSubmitting || createNewClient}
              filterOption={false}
              onSearch={value => {
                const matchingClients = data?.projectManagement?.clients.filter(c =>
                  c.name.toLowerCase().includes(value.toLowerCase()),
                );
                setClientsToShow(matchingClients);
              }}
              onChange={value => {
                if (value) {
                  const client = data?.projectManagement?.clients.find(c => c._id === value);
                  setFieldValue('existingClientId', value);
                  setFieldValue('clientName', client.name);
                  setFieldValue('clientShortName', client.shortName);
                } else {
                  setFieldValue('existingClientId', null);
                  setFieldValue('clientName', null);
                  setFieldValue('clientShortName', null);
                }
              }}
              onBlur={handleBlur}
            >
              {clientsToShow.map(client => (
                <Option key={client._id} value={client._id}>
                  {client.name}
                </Option>
              ))}
            </Select>
            <Checkbox
              checked={createNewClient}
              onChange={e => {
                setCreateNewClient(e.target.checked);
                setFieldValue('existingClientId', null);
                setFieldValue('clientName', null);
                setFieldValue('clientShortName', null);
              }}
            >
              Create New Client
            </Checkbox>
          </Form.Item>
          {createNewClient && (
            <>
              <Form.Item
                label="Client Name"
                hasFeedback
                validateStatus={errors.clientName && touched.clientName && 'error'}
                help={errors.clientName && touched.clientName && errors.clientName}
                required={!values.existingClientId}
              >
                <Input
                  name="clientName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clientName}
                  disabled={isSubmitting || !!values.existingClientId}
                />
              </Form.Item>
              <Form.Item
                label="Client Short Name"
                hasFeedback
                validateStatus={errors.clientShortName && touched.clientShortName && 'error'}
                required={!values.existingClientId}
              >
                <Input
                  name="clientShortName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clientShortName}
                  disabled={isSubmitting || !!values.existingClientId}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Budget"
            validateStatus={errors.budget && touched.budget && 'error'}
            help={(errors.budget && touched.budget && errors.budget) || 'Specify project budget'}
            hasFeedback
          >
            <InputNumber
              className="w-full"
              name="budget"
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              onChange={value => {
                setFieldValue('budget', value);
              }}
              onBlur={handleBlur}
              defaultValue={values.budget}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button disabled={!isValid} loading={isSubmitting} type="primary" htmlType="submit">
              Create Project
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default withApollo(NewProjectForm);
